import { sleep } from "./common/sleep";

// VS CMP Reader
const cmpCcpaCookieName = "usprivacy";

const waitTimeoutMs = 6000; // cmp default timeout

const vsOptOutGdprCookieName = "vsOptOutGdpr";
const vsOptOutCcpaCookieName = "vsOptOutCcpa";
const cookieExpiryDay = 7;

//
// Util
//

/**
 * waits for a function to be defined on `window`, and then invokes the callback with the provided parameter
 *
 * @param stringFnToWait key of function on `window`
 * @param timeoutMs duration to wait between checks (will be increased with a linear backoff of 100ms per call)
 * @param callbackFnToExec called once the window function is available
 * @param callbackParam passed to `callbackFnToExec`
 */
// eslint-disable-next-line @typescript-eslint/ban-types
async function waitForFn<T extends Function, P extends Parameters<T>[0]>(
  stringFnToWait: keyof typeof window,
  timeoutMs: number,
  callbackFnToExec: T,
  callbackParam?: P
) {
  const incrementMs = 100;
  let durationMs = 0;
  let isLoaded = typeof window[stringFnToWait] === "function";
  while (!isLoaded && durationMs < timeoutMs) {
    // intentionally serial async retries
    // eslint-disable-next-line no-await-in-loop
    await sleep(incrementMs);
    durationMs += incrementMs;
    isLoaded = typeof window[stringFnToWait] === "function";
  }
  if (isLoaded) {
    callbackFnToExec(callbackParam);
  }
}

function getCookie(name) {
  const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return v ? v[2] : null;
}
function hasCookie(name) {
  return getCookie(name) !== null;
}
function setCookie(name, value, days) {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
}
function deleteCookie(name) {
  setCookie(name, "", -1);
}

//
// GDPR
//
function updateGdprFn() {
  // eslint-disable-next-line no-underscore-dangle
  window.__tcfapi("getTCData", 2, (tcData, success) => {
    if (success) {
      let hasGivenConsent = true;
      for (let i = 1; i <= 10; i++) {
        // as of Oct 29 2020, there are only 10 purposes
        // ref: https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/TCFv2/IAB%20Tech%20Lab%20-%20Consent%20string%20and%20vendor%20list%20formats%20v2.md#publisher-purposes-transparency-and-consent
        const consentI = tcData.purpose.consents[i];
        if (typeof consentI === "undefined" || !consentI) {
          hasGivenConsent = false;
          break;
        }
      }
      if (hasGivenConsent) {
        deleteCookie(vsOptOutGdprCookieName);
      } else {
        setCookie(vsOptOutGdprCookieName, "true", cookieExpiryDay);
      }
    }
  });
}

//
// CCPA
//
function updateCcpaFn() {
  // eslint-disable-next-line no-underscore-dangle
  window.__uspapi("getUSPData", 1, (uspData, success) => {
    if (success) {
      if (uspData.uspString.charAt(2) === "N") {
        // index 2 is Opt-Out Sale; valid values: 'Y' or 'N'
        // ref: https://github.com/InteractiveAdvertisingBureau/USPrivacy/blob/master/CCPA/US%20Privacy%20String.md#us-privacy-string-format
        deleteCookie(vsOptOutCcpaCookieName);
      } else {
        setCookie(vsOptOutCcpaCookieName, "true", cookieExpiryDay);
      }
    }
  });
}

function addCmpListener(policy: string) {
  // eslint-disable-next-line no-underscore-dangle
  window.__tcfapi("addEventListener", 2, (tcData, success) => {
    if (success) {
      if (tcData.eventStatus === "useractioncomplete") {
        if (policy === "gdpr") {
          updateGdprFn();
        } else {
          console.error("undefined policy:", policy);
        }
      }
    }
  });
}

if (hasCookie(cmpCcpaCookieName)) {
  waitForFn("__uspapi", waitTimeoutMs, updateCcpaFn);
  // by design of the specification, there is no listener to update mid-flight
} else {
  // GDPR - by architecture design, this js only gets loaded if request comes from EU / US
  waitForFn("__tcfapi", waitTimeoutMs, updateGdprFn);
  waitForFn("__tcfapi", waitTimeoutMs, addCmpListener, "gdpr");
}
